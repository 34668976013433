import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { Breadcrumb } from "antd";

class CustomBreadcrumb extends PureComponent {
  render() {
    const { location, routerList } = this.props;
    const pathSnippets = location.pathname.split("/").filter((i) => i);
    let extraBreadcrumbItems = [];
    console.info("router path: ", pathSnippets);
    // CRC医生绑定列表进行特殊路由处理，当路由 pathSnippets=['crc', 'doctors','*****']时，直接设置面包屑=首页/CRC管理/CRC列表/对接医生，并且CRC列表可以跳转
    if (
      pathSnippets.length === 3 &&
      pathSnippets.includes("crc") &&
      pathSnippets.includes("doctors")
    ) {
      extraBreadcrumbItems = [
        <Breadcrumb.Item key='1'>
          <span>CRC管理</span>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key='2' href="/crc/list">
          <span>CRC列表</span>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key='3'>
          <span>对接医生</span>
        </Breadcrumb.Item>,
      ];
    } else if (
      pathSnippets.length === 3 &&
      pathSnippets.includes("care") &&
      pathSnippets.includes("specialistsOrderDetail")
    ) {
      extraBreadcrumbItems = [
        <Breadcrumb.Item key='1'>
          <span>患者服务</span>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key='2' href="/care/specialistsOrderList">
          <span>专家会诊订单列表</span>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key='3'>
          <span>专家会诊订单详情</span>
        </Breadcrumb.Item>,
      ];
    } else {
      extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
        const routeNote = routerList.filter((item) => item.path === url)[0];
        if (routeNote) {
          return (
            <Breadcrumb.Item key={url}>
              <span>{routeNote.name}</span>
            </Breadcrumb.Item>
          );
        }
        return null;
      });
    }
    const breadcrumbItems = [
      <Breadcrumb.Item key="home">
        <span>首页</span>
      </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems);
    return (
      <Breadcrumb style={{ marginBottom: 20 }}>{breadcrumbItems}</Breadcrumb>
    );
  }
}

export default withRouter(CustomBreadcrumb);
