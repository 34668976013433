/* eslint-disable */
import moment from 'moment';
import handleError from '@UTILS/handleError';
import React from 'react';
import JSzip from "jszip";
import { saveAs } from "file-saver";

export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function getMenuData(routerMap) {
  let routes = routerMap;
  if (routerMap['/']) {
    routes = routerMap['/'].children;
  }
  const menu = Object.keys(routes).map(path => {
    const result = {
      path,
      name: routes[path].name,
      icon: routes[path].icon,
      authority: routes[path].authority,
      hidden: routes[path].hidden
    };
    if (routes[path].children) {
      result.children = getMenuData(routes[path].children);
    }
    return result;
  });
  return menu;
}

export function isPC() {
  return window.innerWidth > 768;
}

export function isIosClient() {
  return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}
export function getSystem() {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return 'IOS';
  }
  if (/(Android)/i.test(navigator.userAgent)) {
    return 'Android';
  }
  return 'PC';
}
export function isWeiXin() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') > -1;
}
export function getRouterData(routerMap) {
  const flattenRoute = flatten(routerMap);
  const routes = Object.keys(flattenRoute).map(path => flattenRoute[path]);
  return routes;
}

function flatten(routerMap) {
  let routes = {};
  Object.keys(routerMap).forEach(path => {
    routes[path] = {
      path,
      key: path,
      name: routerMap[path].name,
      exact: routerMap[path].exact || true,
      component: routerMap[path].component
    };
    if (routerMap[path].children) {
      routes = { ...routes, ...flatten(routerMap[path].children) };
    }
  });
  return routes;
}

export const queryString = () => {
  const _queryString = {};
  const _query = window.location.search.substr(1);
  const querys = _query.split('&');
  querys.forEach(v => {
    const _pair = v.split('=');
    if (!_queryString.hasOwnProperty(_pair[0])) {
      _queryString[_pair[0]] = decodeURIComponent(_pair[1]);
    } else if (typeof _queryString[_pair[0]] === 'string') {
      const _arr = [_queryString[_pair[0]], decodeURIComponent(_pair[1])];
      _queryString[_pair[0]] = _arr;
    } else {
      _queryString[_pair[0]].push(decodeURIComponent(_pair[1]));
    }
  });
  return _queryString;
};
export const getQueryString = name => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
};

export const getKeyAndValue = obj => {
  return Object.keys(obj).map(item => ({
    key: item,
    value: obj[item]
  }));
};
export const getListKeyAndValue = list => {
  return list.map(item => ({
    key: item,
    value: item
  }));
};
export const getTopicKeyAndValue = list => {
  return list.map(item => ({
    key: item?.p8Id,
    value: item?.Name?.zh
  }));
};

export const getSelectOptions = obj => {
  return Object.keys(obj).map(key => ({
    key: obj[key].Name,
    value: obj[key].DisplayText
  }));
};

export const getListSelectOptions = (list = []) => {
  return list.map(item => ({
    key: item.Name,
    value: item.DisplayText
  }));
};

export const covertListToObj = (
  list = [],
  config = { key: 'Name', value: 'DisplayText' }
) => {
  const obj = {};
  list.forEach(item => {
    Object.assign(obj, { [item[config.key]]: item[config.value] });
  });
  return obj;
};
let time;
export const lowerRequest = (func, delay) => {
  return (...args) => {
    if (time) {
      clearTimeout(time);
      time = null;
    }
    time = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const caculateTimes = num => {
  // eslint-disable-next-line radix
  const hours = parseInt(num / 3600);
  // eslint-disable-next-line radix
  const residueHour = parseInt(num % 3600);
  // eslint-disable-next-line radix
  const minutes = parseInt(residueHour / 60);
  // eslint-disable-next-line radix
  const seconds = parseInt(num % 60);
  return {
    hours,
    minutes,
    seconds
  };
};

export const caculateTimeLength = (hours, minutes, seconds) => {
  // eslint-disable-next-line no-param-reassign
  !hours && (hours = 0);
  // eslint-disable-next-line no-param-reassign
  !minutes && (minutes = 0);
  // eslint-disable-next-line no-param-reassign
  !seconds && (seconds = 0);
  return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
};

export const secondsToTime = (sec, isDatatime) => {
  const numSec = Number(sec);
  let h = Math.floor(numSec / 3600);
  let m = Math.floor((numSec / 60) % 60);
  let s = Math.floor(numSec % 60);
  if (isDatatime) {
    return {
      hours: h,
      minutes: m,
      seconds: s
    };
  }
  h = h >= 1 ? (h < 10 ? `0${h}时` : `${h}时`) : '';
  m = m >= 1 ? (m < 10 ? `0${m}分` : `${m}分`) : '';
  s = s >= 1 ? (s < 10 ? `0${s}秒` : `${s}秒`) : '';
  return `${h} ${m} ${s}`;
};

export function hasClass(elem, cls) {
  cls = cls || '';
  if (cls.replace(/\s/g, '').length == 0) return false; //当cls没有参数时，返回false
  return new RegExp(' ' + cls + ' ').test(' ' + elem.className + ' ');
}

export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) {
    ele.className = ele.className == '' ? cls : ele.className + ' ' + cls;
  }
}

export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var newClass = ' ' + ele.className.replace(/[\t\r\n]/g, '') + ' ';
    while (newClass.indexOf(' ' + cls + ' ') >= 0) {
      newClass = newClass.replace(' ' + cls + ' ', ' ');
    }
    ele.className = newClass.replace(/^\s+|\s+$/g, '');
  }
}

export function getDateBucket() {
  return moment()
    .utc()
    .format('YYYY-M-D');
}
export function getRecentDay(day = 29) {
  const dateFormat = 'YYYY/MM/DD';
  let lt = moment()
    .subtract(day, 'days')
    .format(dateFormat);
  let now = moment()
    .utc()
    .format(dateFormat);
  return [moment(lt, dateFormat), moment(now, dateFormat)];
}
export function getRecentDayParams(day = 29) {
  const dateFormat = 'YYYY/MM/DD';
  const startString = moment()
    .subtract(day, 'day')
    .format(dateFormat);
  const nowString = moment()
    .utc()
    .format(dateFormat);
  const nowStamp = moment(nowString, dateFormat)
    .endOf('day')
    .valueOf();
  const startStamp = moment(startString, dateFormat).valueOf();
  return {
    startDate: startStamp,
    endDate: nowStamp
  };
}
export function mapList(list, name, keyValue, params) {
  return list.map(
    item => (item[name] === keyValue ? { ...item, ...params } : item)
  );
}

export const validateFn = file => {
  const { size } = file;
  if (size < 2097152) {
    // 2mb file size 2097152
    return true;
  }
  // eslint-disable-next-line
  if (
    window.confirm('上传图片大小超过2Mb, 请确定上传或在图片URL添加参数进行处理')
  ) {
    return true;
  }
  return false;
};

export const addImageLinkIntoHtml = htmlContent => {
  if (htmlContent.indexOf('.jpg"><img') > 0) {
    handleError('有已存在图片链接，请删除旧的图片连接');
    return '';
  }
  if (htmlContent.indexOf('class="media-wrap image-wrap ') > 0) {
    handleError('图片位置非原始位始，请先设置图片成原始左对齐(无居中,右对齐)');
    return '';
  }
  let html = htmlContent;
  const images = html.match(/image-wrap"><img [^>]*src="[^"]*"[^>]*>/gm);
  if (!images || images.length <= 0) {
    handleError('没有找到相关图片');
    return '';
  }
  const urls = images && images.map(x => x.replace(/.*src="([^"]*)".*/, '$1'));
  html = html.replace(/.jpg"\/><\/div>/g, '.jpg"/></a></div>');
  urls.forEach(url => {
    const src = `class="media-wrap image-wrap"><a style="display:inline-block" href="${url}"><img`;
    html = html.replace(/class="media-wrap image-wrap"><img/, src);
  });
  return html;
};

export const getCategoriesText = categories => {
  return (
    <div>
      {categories.map(item => {
        const { Primary, Secondary, MedSerialNum } = item;
        const index = MedSerialNum.lastIndexOf('.');
        const sort = MedSerialNum.substring(index + 1);
        const primaryTitle = Primary?.Title || '/';
        const secondaryTitle = Secondary?.Title || '/';
        const displayText = `${primaryTitle} - ${secondaryTitle} - ${sort}`;
        return <p key={displayText}>{displayText}</p>;
      })}
    </div>
  );
};

export const isValidPassword = password => {
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,16}$/;
  return reg.test(password);
};

export const isHNEnv = process.env.ENV_TYPE === 'hn';

export const downloadFiles = async (files, fileName) => {
  console.info('download files', files)
  const zip = new JSzip();
  const promises = files.map((file) => {
    return new Promise((resolve) => {
      fetch(file.url)
        .then(response => response.arrayBuffer())
        .then(data => {
          zip.file(file.name, data);
          resolve();
        })
        .catch(error => {
          console.error('下载文件失败:', error);
          resolve(); // 即使失败也resolve，确保其他文件能继续下载
        });
    });
  });

  await Promise.all(promises).then(() => {
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${fileName}.zip`);
    });
  });
};
