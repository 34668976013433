// console.log('DBCONFIG ', process.env.Default_Activity_ID);
// console.log('DBCONFIG ', process.env.API_HOST);
// console.log('DBCONFIG ', process.env.APP_API_HOST);
// console.log('DBCONFIG ', process.env.OSS_HOST);
// console.log('DBCONFIG ', process.env.VIDEO_OSS_HOST);
// console.log('DBCONFIG ', process.env.OSS_BUCKET);
// console.log('DBCONFIG ', process.env.OSS_ENDPOINT);

const {
  OSS_HOST,
  VIDEO_OSS_HOST,
  OSS_BUCKET,
  OSS_ENDPOINT,
  OSS_VIDEO_BUCKET,
  APP_API_HOST,
  CARE_HOST,
  OSS_PRIVATE_BUCKET,
} = process.env;

window.isHNEnv = OSS_BUCKET === 'pier-demo-1';

export default {
  baseUrl: process.env.API_HOST,
  appBaseUrl: process.env.APP_API_HOST,
  careBaseUrl: CARE_HOST,
  ossHost: OSS_HOST,
  privateOssHost: OSS_HOST,
  staticOpportunity: `${OSS_HOST}/static/img/opportunity/`,
  staticMedWordAudioUrl: `${OSS_HOST}/static/audio/medword/`,
  staticMedWordImageUrl: `${OSS_HOST}/static/img/medword/`,
  staticImgUrl: `${OSS_HOST}/static/img/certificates/`,
  staticAvatarUrl: `${OSS_HOST}/static/img/users/`,
  staticDoctorAvatarUrl: `${OSS_HOST}/static/img/doctors/`,
  staticPatientAvatarUrl: `${OSS_HOST}/static/img/careusers/`,
  staticChatImageUrl: `${OSS_HOST}/static/img/chat/`,
  staticCareChatImageUrl: `${OSS_HOST}/static/img/carechat/`,
  staticPostImageUrl: `${OSS_HOST}/static/img/post/`,
  staticGroupImageUrl: `${OSS_HOST}/static/img/groups/`,
  staticConsultation: `${OSS_HOST}/static/img/consultation/`,
  staticGroupDetailImageUrl: `${OSS_HOST}/static/img/groups/image/`,
  staticGroupDetailVideoUrl: `${OSS_HOST}/static/img/groups/video/`,
  staticPdfUrl: `${OSS_HOST}/static/docs/guide/`,
  caseHistoryUrl: `${OSS_HOST}/patient/`,
  treatmentAdvicePdfUrl: `${OSS_HOST}/patient/treatment/`,
  staticAcademyIcon: `${OSS_HOST}/static/img/icon/`,
  staticBannerImageUrl: `${OSS_HOST}/static/img/banner/`,
  courseVideoUrl: `${VIDEO_OSS_HOST}/course/video/`,
  coursePreviewUrl: `${VIDEO_OSS_HOST}/course/preview/`,
  staticSurveyTemplate: `${OSS_HOST}/static/img/surveytemplate/`,
  staticPainManagementUrl: `${OSS_HOST}/static/img/painmanagement/`,
  ossBucket: OSS_BUCKET,
  ossVideoBucket: OSS_VIDEO_BUCKET,
  ossPrivateBucket: OSS_PRIVATE_BUCKET,
  ossEndPoint: OSS_ENDPOINT || 'https://oss-cn-hangzhou.aliyuncs.com',
  defaultActivityId: process.env.Default_Activity_ID,
  careEventUrl:
    'https://pier.oss-cn-hangzhou.aliyuncs.com/analytic/care/ANALYTICS_EVENT_ENUM.json',
  appEventUrl:
    'https://pier.oss-cn-hangzhou.aliyuncs.com/analytic/app/ANALYTICS_EVENT_ENUM.json'
};
